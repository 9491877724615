import React from "react";

const AllTheZen = () => {
  const kittyGroups = [
    {
      groupName: "Page",
      kitties: [
        { name: "Page", emanates: "—", accumulates: "—", petBonus: "—" },
      ],
    },
    {
      groupName: "Page's Gang",
      kitties: [
        {
          name: "Gwendolion The Champion",
          emanates: "—",
          accumulates: "—",
          petBonus: "—",
        },
        {
          name: "Bartholomew The Trickster",
          emanates: "—",
          accumulates: "—",
          petBonus: "—",
        },
        {
          name: "Purrzival The Paladin",
          emanates: "—",
          accumulates: "—",
          petBonus: "—",
        },
        {
          name: "Pawderick The Lancer",
          emanates: "—",
          accumulates: "—",
          petBonus: "—",
        },
        {
          name: "Catseye The Ranger",
          emanates: "—",
          accumulates: "—",
          petBonus: "—",
        },
      ],
    },
    {
      groupName: "Footballer",
      kitties: [
        { name: "Keeper", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Striker", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Winger", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Sweeper", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Boot", emanates: "—", accumulates: "—", petBonus: "—" },
      ],
    },
    {
      groupName: "Crossbreed",
      kitties: [
        { name: "Furrmingo", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Flutterbee", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Ducat", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Lulu", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Dog", emanates: "—", accumulates: "—", petBonus: "—" },
      ],
    },
    {
      groupName: "Kittenheim",
      kitties: [
        { name: "Stitches", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Jack", emanates: "—", accumulates: "—", petBonus: "—" },
        { name: "Furmione", emanates: "—", accumulates: "—", petBonus: "—" },
      ],
    },
    {
      groupName: "Band",
      kitties: [
        {
          name: "Joan",
          emanates: "7,920 trillion ZEN/s",
          accumulates: "18,000 trillion ZEN/s",
          petBonus: "11,000 quadrillion ZEN/s",
        },
        { name: "Janis", emanates: "—", accumulates: "—", petBonus: "—" },
        {
          name: "Travis",
          emanates: "13,500 trillion ZEN/s",
          accumulates: "10,560 trillion ZEN/s",
          petBonus: "11,000 quadrillion ZEN/s",
        },
        {
          name: "Steel",
          emanates: "11,250 trillion ZEN/s",
          accumulates: "10,800 trillion ZEN/s",
          petBonus: "11,000 quadrillion ZEN/s",
        },
        { name: "????", emanates: "—", accumulates: "—", petBonus: "—" },
      ],
    },
  ];

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">ALL THE ZEN!: Game Overview</h2>

      <p>
        <strong>ALL THE ZEN!</strong> is set in the CryptoKitties multiverse,
        where players hatch and interact with Fancy Kitties. The goal is to earn
        as much ZEN as possible through strategy, timing, and upgrades. Unlike
        traditional CryptoKitties, the Fancy Kitties in this game are not
        permanent NFTs but are temporary companions aiding in your ZEN journey.
        The game is divided into seasons, with potential changes to gameplay
        mechanics. Stay connected with the community on Telegram:{" "}
        <a
          href="https://t.me/cryptokitties"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          Join the CryptoKitties Telegram
        </a>
        .
      </p>

      <h3 className="text-xl font-bold mt-6 mb-2">Egg Overview</h3>
      <p>
        The <strong>Egg NFT Collection</strong> launched on August 30, 2024, on
        OpenSea, offering a new dimension to the CryptoKitties universe. Eggs
        come in Common, Uncommon, and Rare tiers, initially priced at{" "}
        <strong>0.008 ETH</strong>. Within 24 hours, over{" "}
        <strong>3,100 Eggs</strong> were minted. While the exact functionality
        of the Eggs in the game remains uncertain, they are expected to play a
        significant role in future updates.
      </p>
      <p>
        Some upcoming features in the game may include tracking total Eggs,
        total CryptoKitties, total Trophies, total Gen0s, total ZEN, and total
        Prize Packs. The Eggs also add an element of mystery, as clues and
        puzzles, like{" "}
        <strong>
          <a
            href="https://allthezen.cryptokitties.co/post/clue-2-solved"
            target="_blank"
            rel="noopener noreferrer"
          >
            Clue 2
          </a>
        </strong>
        , hint at future gameplay strategies.
      </p>
      <p>
        Explore the Egg NFT collection on OpenSea:{" "}
        <a
          href="https://opensea.io/collection/ckatzegg"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          CKATZ Egg Collection
        </a>
        .
      </p>

      <h3 className="text-xl font-bold mt-6 mb-2">How to Play</h3>
      <p>
        Start by hatching a Fancy Kitty guided by your companion, Felis. You'll
        hatch your first Fancy Kitty, Page, from a Page Egg. As you progress,
        rub the ReGenesis Egg (the "Big Egg") to earn ZEN, which can be spent to
        hatch new Fancies and refill your TAO bar. Unlock more Fancy Families,
        catch Special Fancies on the Fancy Parade, upgrade your Fancies, earn
        Trophies, and look out for seasonal resets. For more details, visit the{" "}
        <a
          href="https://allthezen.cryptokitties.co/#learn"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 underline"
        >
          How to Play page
        </a>
        .
      </p>

      <h3 className="text-xl font-bold mt-6 mb-2">Definitions</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>ZEN</strong>: The core resource of the game, earned passively
          or by interacting with the Big Egg.
        </li>
        <li>
          <strong>Fancy Kitties</strong>: Unique Kitties that boost your ZEN
          efficiency but aren't owned as NFTs.
        </li>
        <li>
          <strong>ReGenesis Egg (Big Egg)</strong>: Central entity to pet for
          ZEN; bonuses depend on how much ZEN is accumulated.
        </li>
        <li>
          <strong>Emanation</strong>: Passively earned ZEN that can be spent
          immediately.
        </li>
        <li>
          <strong>Accumulation</strong>: Stored ZEN in the Big Egg, released by
          petting or Special Effects.
        </li>
        <li>
          <strong>TAO Bar</strong>: Refills when you hatch a new Fancy Egg,
          boosting ZEN from the Big Egg.
        </li>
        <li>
          <strong>Fancy Parade</strong>: Special Fancies appear, triggering
          unique effects when tapped.
        </li>
        <li>
          <strong>Special Effects</strong>: Temporary boosts triggered by
          interacting with Special Fancies.
        </li>
        <li>
          <strong>Trophies</strong>: Achievements for reaching specific
          milestones.
        </li>
        <li>
          <strong>Ninjas</strong>: Rare entities with powerful effects:
          <ul className="list-disc list-inside pl-6">
            <li>
              <strong>Red Ninja</strong>: "Instantly Receive 50% of the ZEN from
              the ReGenesis egg (up to 6 hours' worth of accumulation)"
            </li>
            <li>
              <strong>Black Ninja</strong>: "Instantly Receive 38 minutes' worth
              of ZEN (up to the ZEN accumulated in the ReGenesis egg)"
            </li>
          </ul>
        </li>
      </ul>

      <h3 className="text-xl font-bold mt-6 mb-2">Advanced Notes</h3>
      <ul className="list-disc list-inside mb-4">
        <li>Monitor seasonal resets, as gameplay dynamics may change.</li>
        <li>
          Balance ZEN spending and accumulation to optimize your progress.
        </li>
        <li>
          Look out for Special Fancies on the Fancy Parade for unique boosts.
        </li>
        <li>
          Unlock new Fancy Families as you accumulate more ZEN to increase
          production.
        </li>
        <li>
          Use clues and puzzles to uncover new strategies and gameplay secrets.
        </li>
        <li>
          Buying a Kitty and opening a normal Egg will earn you 20 seconds'
          worth of your gold accumulation rate. For example, if you earn 10
          trillion ZEN per second for accumulation, opening a normal Egg will
          give you 200 trillion ZEN. Thus, buying any Kitty under 200 trillion
          ZEN will be profitable.
        </li>
      </ul>

      <h3 className="text-xl font-bold mt-6 mb-2">Trophies</h3>
      <ul className="list-disc list-inside mb-4">
        <li>
          <strong>Kaizen</strong>: Unlocked by "Own 500 Fancy levels"
        </li>
      </ul>

      <h3 className="text-xl font-bold mt-6 mb-2">Kitty Explorer</h3>
      <p className="mb-4">
        The Kitty Explorer offers a detailed look at different Kitty groups,
        each with unique traits that affect ZEN production. The following chart
        outlines their ZEN stats:
      </p>

      <table className="table-auto w-full mb-4">
        <thead>
          <tr>
            <th className="px-4 py-2">Kitty Group</th>
            <th className="px-4 py-2">Kitty</th>
            <th className="px-4 py-2 text-purple-500">Emanates</th>
            <th className="px-4 py-2 text-yellow-500">Accumulates</th>
            <th className="px-4 py-2 text-lime-500">Pet Bonus</th>
          </tr>
        </thead>
        <tbody>
          {kittyGroups.map(({ groupName, kitties }) =>
            kitties.map((kitty, index) => (
              <tr key={`${groupName}-${kitty.name}`}>
                {index === 0 && (
                  <td className="border px-4 py-2" rowSpan={kitties.length}>
                    {groupName}
                  </td>
                )}
                <td className="border px-4 py-2">{kitty.name}</td>
                <td className="border px-4 py-2 text-purple-500">
                  {kitty.emanates}
                </td>
                <td className="border px-4 py-2 text-yellow-500">
                  {kitty.accumulates}
                </td>
                <td className="border px-4 py-2 text-lime-500">
                  {kitty.petBonus}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AllTheZen;
