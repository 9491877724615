// config.js

import * as fcl from "@onflow/fcl";

// Configure FCL
fcl
  .config()
  .put("accessNode.api", "https://rest-mainnet.onflow.org") // Flow mainnet access node
  .put("discovery.wallet", "https://fcl-discovery.onflow.org/authn") // FCL Wallet Discovery
  .put("discovery.authn.endpoint", "https://fcl-discovery.onflow.org/api/authn")
  .put("walletconnect.projectId", "1c74f5c9166063f0f3d2eb0cc6b30265")
  .put("app.detail.title", "FlowConnect")
  .put("app.detail.icon", "https://flowconnect.io/logo.png")
  .put("app.detail.description", "Connecting Flow Communities")
  .put("app.detail.url", "https://flowconnect.io");
