import React, { useEffect, useState, useMemo } from "react";
import TopShotLogo from "../logos/TopShot-white.svg";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as fcl from "@onflow/fcl";
import { FaInstagram, FaTwitter, FaDiscord, FaReddit } from "react-icons/fa";
import TopShotSeries from "./TopShotSeries";
import TopShotCommunities from "./TopShotCommunities";
import Timeline from "./Timeline";
import TopShotGaming from "./TopShotGaming";
import TopShotAnalytics from "./TopShotAnalytics";
import {
  loadSetImage,
  getSetBackground,
  getTopShotSetTier,
} from "./GenericHelpers";
import TopShotChallenges from "./TopShotChallenges";

const TopShot = () => {
  const { seriesId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [setData, setSetData] = useState([]);
  const [series, setSeries] = useState([]);
  const [completedSetIDs, setCompletedSetIDs] = useState([]);
  const [imageCache, setImageCache] = useState({});
  const [loading, setLoading] = useState(false);

  // Adjusted activeTab calculation
  const pathSegments = location.pathname.split("/").filter(Boolean);
  const activeTab = pathSegments[1] || "moment-explorer";

  useEffect(() => {
    fcl.currentUser().subscribe((user) => {
      setUser(user?.addr ? user : null);
    });
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [seriesRes, setsRes] = await Promise.all([
        fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-series"
        ).then((res) => res.json()),
        fetch(
          "https://flowconnectbackend-864654c6a577.herokuapp.com/topshot-sets"
        ).then((res) => res.json()),
      ]);

      setSeries(seriesRes.sort((a, b) => a.id - b.id));
      setSetData(setsRes || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cachedSetImages = useMemo(() => {
    const updatedImageCache = { ...imageCache };

    setData.forEach((set) => {
      if (!updatedImageCache[set.id]) {
        loadSetImage(set.id, "topshot").then((imageUrl) => {
          updatedImageCache[set.id] = imageUrl;
          setImageCache(updatedImageCache);
        });
      }
    });

    return updatedImageCache;
  }, [setData, imageCache]);

  const selectedSeries =
    seriesId !== undefined && seriesId !== null
      ? parseInt(seriesId)
      : series[0]?.id || 0;

  const handleTabChange = (tab) => {
    navigate(`/topshot/${tab}`);
  };

  return (
    <div className="p-2">
      {/* Banner with logo and social links */}
      <div className="flex flex-col lg:flex-row items-center bg-gradient-to-r from-blue-400 via-gray-400 to-red-400 p-6 rounded-lg shadow-lg">
        <a
          href="https://nbatopshot.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="lg:flex-shrink-0 hover:scale-105 transition-transform duration-300 p-2 rounded-lg"
        >
          <img src={TopShotLogo} alt="NBA TopShot Logo" className="h-20" />
        </a>
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4 mt-4 lg:mt-0 lg:ml-8">
          <a
            href="https://www.instagram.com/nbatopshot/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaInstagram className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Instagram</span>
          </a>
          <a
            href="https://x.com/nbatopshot"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaTwitter className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">X</span>
          </a>
          <a
            href="https://discord.gg/nbatopshot"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaDiscord className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Discord</span>
          </a>
          <a
            href="https://www.reddit.com/r/nbatopshot/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-purple-200 flex items-center hover:scale-105 transition-transform duration-300"
          >
            <FaReddit className="h-6 w-6" />
            <span className="ml-2 hidden lg:inline">Reddit</span>
          </a>
        </div>
      </div>

      {/* Navigation Tabs */}
      <div className="flex space-x-4 mt-4 mb-8 border-b-2 pb-2">
        <button
          onClick={() => handleTabChange("moment-explorer")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "moment-explorer"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Moment Explorer
        </button>

        <button
          onClick={() => handleTabChange("communities")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "communities"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Communities
        </button>

        <button
          onClick={() => handleTabChange("timeline")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "timeline"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Timeline
        </button>

        <button
          onClick={() => handleTabChange("gaming")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "gaming"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Gaming
        </button>

        <button
          onClick={() => handleTabChange("analytics")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "analytics"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Analytics
        </button>

        <button
          onClick={() => handleTabChange("challenges")}
          className={`px-4 py-2 rounded-lg transition-transform duration-300 hover:scale-105 ${
            activeTab === "challenges"
              ? "bg-green-500 text-white"
              : "bg-gray-200 text-black"
          }`}
        >
          Challenges
        </button>
      </div>

      {/* Content Based on Active Tab */}
      {(activeTab === "moment-explorer" || activeTab === "series") && (
        <TopShotSeries
          series={series}
          selectedSeries={selectedSeries}
          setData={setData}
          cachedSetImages={cachedSetImages}
          completedSetIDs={completedSetIDs}
          getTopShotSetTier={getTopShotSetTier}
          getSetBackground={getSetBackground}
        />
      )}

      {activeTab === "communities" && <TopShotCommunities />}

      {activeTab === "timeline" && <Timeline defaultProject="TopShot" />}

      {activeTab === "gaming" && <TopShotGaming />}

      {activeTab === "analytics" && <TopShotAnalytics />}

      {activeTab === "challenges" && <TopShotChallenges />}
    </div>
  );
};

export default TopShot;
